<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <div class="theme_one_plot" v-if="!course">
            <el-skeleton :rows="6"/>
          </div>
          <div class="theme_one_plot" v-else>
            <router-link :to="{ name: 'about-course', slug: course.slug }" class="theme_one_plot_theme">
              {{ $t('About_course') }}
            </router-link>
            <router-link
              v-for="module in course.modules"
              :to="{ name: 'course-module', params: { slug: course.slug, module: module.id } }"
              class="theme_one_plot_theme">
              {{ module.title }}
            </router-link>
            <router-link v-if="course.quiz" :to="{ name: 'final-testing', params: { slug: course.slug }}"
                         class="module_one_plot_about">
              {{ $t('Final_testing') }}
            </router-link>
          </div>
        </div>
        <div class="theme_one_exercises_desc">
          <div class="theme_one_exercises_desc_text_container" v-if="!(error && success)">
            <div class="theme_one_modal_save_result" v-if="success">
              <div class="theme_one_modal_result_text">{{ $t('Response_saved_successfully') }}</div>
              <div class="theme_one_modal_result_img">
              </div>
            </div>
            <div class="theme_one_modal_result_bad" v-if="finished &&  quiz.mode=='ONLY_RAW_ANSWERS'">
              <div class="theme_one_modal_bad_text_block">
                <div class="theme_one_modal_bad_result_text w-100">
                  <h5>{{ $t('endTask') }}!</h5>
                </div>
                <div v-if="!quizEnded" class="theme_one_modal_bad_result_btn pointer" @click="refreshQuiz">{{
                    $t('try_again')
                  }}
                </div>
              </div>
            </div>
            <div class="theme_one_modal_result_bad"
                 v-if="finished && result < quiz.passing_score && quiz.mode!='ONLY_RAW_ANSWERS'">
              <div class="theme_one_modal_bad_text_block">
                <div class="theme_one_modal_bad_result_text w-100">
                  <h5>{{ $t('Sorry_you_did_not_pass_the_test') }}</h5>
                  <br>
                  <h6>{{ $t('Your_score') }}: {{ percent }}%. Вы набрали {{ result }} из {{ max_score }} баллов.
                    <br>
                    <br>Проходной балл {{ passing_score }}</h6>
                </div>
                <div v-if="!quizEnded" class="theme_one_modal_bad_result_btn pointer" @click="refreshQuiz">{{
                    $t('try_again')
                  }}
                </div>
                <div v-else class="theme_one_modal_bad_result_btn pointer" @click="finished=false">Закрыть</div>
              </div>
            </div>


            <div class="theme_one_modal_result_bad"
                 v-if="finished && result >= quiz.passing_score">
              <div class="theme_one_modal_bad_text_block">
                <div class="theme_one_modal_bad_result_text w-100">
                  {{ $t('Congratulations!_You_have_passed_the_test') }}
                  <br><br>
                  {{ $t('Your_score') }}: {{ percent }}%. Вы набрали {{ result }} из {{ max_score }} баллов.
                  <br>Проходной балл {{ passing_score }}
                </div>

                <div class="theme_one_modal_bad_result_btn pointer" @click="finished=false">Закрыть</div>
              </div>
            </div>


            <div class="theme_one_desc_title">{{ finished ? 'Результаты теста: ' : '' }}{{ quiz.title }}</div>
            <div class="theme_one_desc_test w-100">
              <!--        <div class="theme_one_modal_result_good" v-if="true">-->
              <!--          <div class="theme_one_modal_result_text">Ответ успешно сохранен</div>-->
              <!--          <div class="theme_one_modal_result_img">-->
              <!--          </div>-->
              <!--        </div>-->

              <!--        <div class="theme_one_modal_result_bad" v-if="finished && result < quiz.passing_score">-->
              <!--          <div class="theme_one_modal_bad_text_block">-->
              <!--            <div class="theme_one_modal_bad_result_text">-->
              <!--              К сожалению, вы не прошли тестирование-->
              <!--              <br>-->
              <!--              Ваш результат: {{ result }} из {{ quiz.passing_score }}-->
              <!--            </div>-->
              <!--            <div class="theme_one_modal_bad_result_btn pointer" @click="reloadExercise">Попробовать заново</div>-->
              <!--          </div>-->
              <!--          <div class="theme_one_modal_result_img"></div>-->
              <!--        </div>-->
              <!--        <div class="theme_one_modal_result_good" v-if="finished && result >= quiz.passing_score">-->
              <!--          <div class="theme_one_modal_result_text">-->
              <!--            Поздравляем! Вы прошли тестирование.-->
              <!--            <br>-->
              <!--            Ваш результат: {{ result }} из {{ quiz.passing_score }}-->
              <!--          </div>-->
              <!--          <div class="theme_one_modal_result_img">-->
              <!--          </div>-->
              <!--        </div>-->

              <div class="theme_one_modal_result_bad" v-if="error">
                <div class="theme_one_modal_bad_text_block">
                  <div class="theme_one_modal_bad_result_text">{{ $t('An_error_has_occurred') }}</div>
                  <div class="theme_one_modal_bad_result_btn">{{ $t('Try_again') }}</div>
                </div>
                <div class="theme_one_modal_result_img"></div>
              </div>

              <div v-else>
                <div v-if="currentQuestion && currentQuestion.text" class="theme_one_desc_text"
                     v-html="currentQuestion.text"></div>
                <div v-if="currentQuestion && currentQuestion.file">
                  <audio v-if="currentQuestion.file.type === 'audio'" :src="apiRoot + currentQuestion.file.path"
                         controls></audio>
                </div>
                <div v-if="currentQuestion && currentQuestion.file" class="mb-5 w-100 text-center">
                  <a
                    target="_blank"
                    :href="apiRoot + currentQuestion.file.path"
                    v-if="currentQuestion.file.type === 'file'"
                    class="theme_one_desc_download"
                  >
                    {{ $t('Download_file') }}
                  </a>
                </div>
                <div
                  class="my_courses_list_radio_block"
                  v-if="(currentQuestion && currentQuestion.type === 'TYPE_SINGLE') || (currentQuestion && currentQuestion.type === 'TYPE_MULTIPLE')">
                  <div
                    class="my_courses_radio_item"
                    v-if="currentQuestion && currentQuestion.answers"
                    v-for="answer in currentQuestion.answers">
                    <input :disabled="quizEnded" v-if="currentQuestion.type === 'TYPE_SINGLE'" v-model="selectedAnswer"
                           :value="answer.id"
                           :id="`answer${answer.id}`" type="radio" name="answer[]" class="my_courses_list_radio"/>
                    <input :disabled="quizEnded" v-if="currentQuestion.type === 'TYPE_MULTIPLE'"
                           v-model="selectedAnswer"
                           :value="answer.id"
                           :id="`answer${answer.id}`" type="checkbox" name="answer[]" class="my_courses_list_radio"/>
                    <label :for="`answer${answer.id}`" class="my_courses_radio_label pointer pt-3"
                           v-html="answer.text"></label>
                  </div>
                </div>
                <div v-else>
                  <textarea :disabled="quizEnded" class="form-control mb-5" v-model="selectedAnswerRaw"/>
                </div>
              </div>
            </div>
            <div class="theme_one_desc_btn" v-if="!finished">
              <!--        <a @click="saveAnswer" class="theme_one_desc_btn_back pointer">Сохранить ответ</a>-->
              <a v-if="!firstQuestion" @click="prev" class="theme_one_desc_btn_back pointer">{{ $t('Back') }}</a>
              <a v-if="!lastQuestion" @click="next" class="theme_one_desc_btn_next pointer">{{ $t('Further') }}</a>


              <a v-if="lastQuestion && quizEnded==false" @click="finish"
                 class="theme_one_desc_btn_next pointer">{{ $t('Finish') }}</a>
            </div>
            <div class="theme_one_desc_btn button-quiz-ended-wrapper" v-if="quizEnded">
              <a v-if="quiz.mode === 'DEFAULT'" @click="showResults"
                 class="button-quiz-ended">Показать результаты</a>

              <a v-if="quiz.can_refresh && !( result >= quiz.passing_score)" @click="refreshQuiz"
                 class="button-quiz-ended">Пройти заново</a>
            </div>

          </div>
          <div class="theme_one_exercises_desc_text_container" v-else>
            <div class="theme_one_desc_test w-100" style="height: 400px">
              <div class="theme_one_modal_result_bad">
                <div class="theme_one_modal_bad_text_block">
                  <div class="theme_one_modal_bad_result_text">{{ $t('Data_not_found') }}</div>
                  <div class="theme_one_modal_bad_result_btn">{{ $t('Try_again') }}</div>
                </div>
                <div class="theme_one_modal_result_img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="show" hide-footer hide-header centered size="lg">
      <div class="store__product__modal" id="dialog" style="    background: #F7E9F6; color: #C460C0;">
        <button
          @click="$bvModal.hide('show')"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"></span>
        </button>
        <h1>Вы успешно завершили курс</h1>
        <br>
        На ваш счет начислено 25 коинов
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CourseExercise",
  data() {
    return {
      finished: false,
      results: [],
      result: 0,
      percent: 0,
      passing_score: 0,
      max_score: 0,
      success: false,
      error: false,
      quiz: false,
      apiRoot: window.API_ROOT,
      selectedAnswer: [],
      selectedAnswerRaw: null,
      currentQuestionId: 0,
      course: false,
      quizEnded: false,
      triesAvalible: false,
      savedAnswers: [],
    }
  },
  methods: {
    showResults(finished = true) {
      this.$http.get(`${this.apiRoot}/api/course/quiz/get-user-answers/${this.quiz.id}`)
        .then(res => {
          this.results = res.body.data
          this.result = res.body.result
          this.passing_score = res.body.passing_score
          this.percent = res.body.percent
          this.max_score = res.body.max_score
          this.error = false
          this.success = false
          this.finished = finished
        }).catch(err => {
        Vue.toastr({
          message: 'Error',
          description: 'Не удалось завершить тест',
          type: 'error'
        })
      })
        .finally(() => {
        })
    },
    getQuiz(quizEnded = true) {
      let slug = this.$route.params.slug
      let quiz = this.$route.params.quiz
      this.$http.get(`${this.apiRoot}/api/course/${slug}/quiz/${quiz}`)
        .then(res => {
          this.quiz = res.body
          this.triesAvalible = this.quiz.user_tries > 0
          if (quizEnded) {
            this.showResults(false)
            let saved = []

            for (let i = 0; i < res.body.questions.length; i++) {

              if (res.body.questions[i].user_answer.answer[0]) {
                saved.push(true)
              } else if (res.body.questions[i].user_answer.answer_raw) {
                saved.push(true)
              }
            }
            if (saved.length == res.body.questions.length) {
              this.quizEnded = true
            }
          }


        })
        .catch(err => console.log(err))
        .finally(() => {

        })
    },

    getCourseDetails() {
      let slug = this.$route.params.slug
      this.$http.get(`${this.apiRoot}/api/course/${slug}`)
        .then(res => {
          this.course = res.body
        })
        .catch(err => console.log(err))
        .finally(() => {

        })
    },
    saveAnswer() {
      let params = (this.selectedAnswerRaw !== null) ? {
        answer: this.selectedAnswerRaw,
        answer_raw: this.selectedAnswerRaw,
        question_id: this.currentQuestion.id,
        quiz_id: this.quiz.id
      } : {
        answer: this.selectedAnswer,
        question_id: this.currentQuestion.id,
        quiz_id: this.quiz.id
      }
      this.$http.post(`${this.apiRoot}/api/course/quiz/answer/store`, params).then(res => {
        // if (res.body.message === 'saved')
        //   this.success = true
        // Vue.toastr({
        //   message: 'Success',
        //   description: 'Ответ сохранен',
        //   type: 'success'
        // })
        // setTimeout(() => {
        //   this.success = false
        // }, 1000)
      }).catch(err => {
        // if (err.body.message === 'not_saved')
        // this.error = true
        // Vue.toastr({
        //   message: 'Error',
        //   description: 'Не удалось сохранить ответ',
        //   type: 'error'
        // })

        // setTimeout(() => {
        //   this.error = false
        // }, 1000)
      })
        .finally(() => {
        })
    },
    refreshQuiz() {
      this.quizEnded = false;
      this.triesAvalible = false;
      this.savedAnswers = [];
      this.finished = false;
      this.results = [];
      this.result = 0;
      this.percent = 0;
      this.passing_score = 0;
      this.max_score = 0;
      this.success = false;
      this.error = false;
      this.quiz = false;
      this.apiRoot = window.API_ROOT;
      this.selectedAnswer = [];
      this.selectedAnswerRaw = null;
      this.currentQuestionId = 0;
      let quiz = this.quizId

      this.$http.post(`${this.apiRoot}/api/course/quiz/refresh/${quiz}`)
        .then(res => {

        })
        .catch(err => console.log(err))
        .finally(() => {
          this.getQuiz(false)
        })

    },
    prev() {
      if (!this.currentQuestionId) return;
      if (this.selectedAnswerRaw !== 'null' || this.selectedAnswer.length) {
        this.saveAnswer();
      }
      this.currentQuestionId--
      this.error = false
      this.success = false
      this.selectedAnswer = []
      this.selectedAnswerRaw = null
      if (this.currentQuestion.user_answer) {
        if (this.currentQuestion.user_answer.answer) {
          if (this.currentQuestion.user_answer.answer.length == 1) {
            this.selectedAnswer = this.currentQuestion.user_answer.answer[0]
          } else {
            this.selectedAnswer = this.currentQuestion.user_answer.answer
          }
        }
        if (this.currentQuestion.user_answer.answer_raw) {
          this.selectedAnswerRaw = this.currentQuestion.user_answer.answer_raw
        }
      }
    },
    next() {
      if (this.selectedAnswerRaw !== 'null' || this.selectedAnswer.length) {
        this.saveAnswer();
      }
      this.currentQuestionId++
      this.error = false
      this.success = false
      this.selectedAnswer = []
      this.selectedAnswerRaw = null
    },
    finish() {
      this.saveAnswer();
      if (this.quiz.mode === 'DEFAULT') {
        this.$http.get(`${this.apiRoot}/api/course/quiz/get-user-answers/${this.quiz.id}`)
          .then(res => {
            this.results = res.body.data
            this.result = res.body.result
            this.passing_score = res.body.passing_score
            this.percent = res.body.percent
            this.max_score = res.body.max_score
            this.error = false
            this.success = false
            this.finished = true
            if (this.finished && this.result >= this.quiz.passing_score) {
              this.$bvModal.show('show')
            }
          }).catch(err => {
          Vue.toastr({
            message: 'Error',
            description: 'Не удалось завершить тест',
            type: 'error'
          })
        })
          .finally(() => {

          })
      } else {
        this.$router.push({name: 'final-testing', params: {slug: this.$route.params.slug}})
      }

    },
    reloadExercise() {
      window.location.reload()
    }
  },
  computed: {
    currentQuestion() {
      if (!this.quiz.questions) {
        return;
      }
      let item = this.quiz.questions.find((question, key) => {
        return key == this.currentQuestionId;
      })
      if (item.user_answer) {
        if (item.user_answer.answer) {
          if (item.user_answer.answer.length == 1) {
            this.selectedAnswer = item.user_answer.answer[0]
          } else {
            this.selectedAnswer = item.user_answer.answer
          }
        }
        if (item.user_answer.answer_raw) {
          this.selectedAnswerRaw = item.user_answer.answer_raw
        }
      }

      return item;
    }
    ,
    currentModule: function () {
      if (!(this.quiz.course && this.quiz.course.modules)) return false;
      return this.quiz.course.modules.find(module => module.id === this.quiz.module_id)
    }
    ,
    currentModuleId: function () {
      if (!(this.quiz && this.quiz.owner))
        return;
      return this.quiz.owner.course_chapter_id
    }
    ,
    currentTheme: function () {
      if (!this.currentModule) return false;
      return this.currentModule.themes.find(theme => theme.id === this.quiz.theme_id)
    }
    ,
    lastQuestion: function () {
      return this.currentQuestionId + 1 === this.quiz.questions.length;
    }
    ,

    firstQuestion: function () {
      return this.currentQuestionId == 0;
    }
  },
  mounted() {
    this.getCourseDetails()
    this.getQuiz()
  }
}
</script>

<style scoped>
.theme_one_block .theme_one_main_block .theme_one_main_container .theme_one_content_container .theme_one_plot .theme_one_plot_theme::after {
  display: none;
}

.module_one_plot_about {
  text-decoration: none;
}
</style>
<style scoped>
.button-quiz-ended-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.button-quiz-ended {
  cursor: pointer;
  height: 52px;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #C460C0;
  border-radius: 42px;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
}
</style>
